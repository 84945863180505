// Airbrake Javascript Error Capture
import { Notifier } from '@airbrake/browser';
import { notifyAirbrakeWarnings, skipAirbrakeErrors } from 'packs/airbrake_exceptions';

const airbrake = new Notifier({
    projectId: 263958,
    projectKey: '7e347cd6a111c2cba7894a8ae78f4cae'
});

airbrake.addFilter((notice) => {
    if (skipAirbrakeErrors(notice)) {
        return null;
    }
    notice.context.environment = process.env.RAILS_ENV;
    if (notice.context.environment === 'development' || notice.context.environment === 'test') {
        return null;
    }
    return notice;
});

// Console Log Capture
/*
(function(){
    const oldLog = console.log;
    console.log = function (message) {
        airbrake.notify({
            error: message,
            context: { severity: 'info' },
        })
        oldLog.apply(console, arguments);
    };
})();
*/
// Console Warn Capture
(function(){
    const oldWarn = console.warn;
    console.warn = function (message) {
        if(notifyAirbrakeWarnings(message)) {
            airbrake.notify({
                error: message,
                context: { severity: 'warning' },
            })
            oldWarn.apply(console, arguments);
        }
    };
})();
// Console Error Capture
(function(){
    const oldError = console.error;
    console.error = function (message) {
        if(notifyAirbrakeWarnings(message)) {
            airbrake.notify({
                error: message,
                context: { severity: 'error' },
            })
            oldError.apply(console, arguments);
        }
    };
})();
